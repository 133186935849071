import React, { Component } from 'react'
import Modal from 'react-responsive-modal'

import style from '../styles/lightboxImage.module.css'

export class LightboxImage extends Component {
  state = {
    open: false,
  };

  openModal = () => {
    this.setState({ open: true })
  };

  closeModal = () => {
    this.setState({ open: false })
  };

  render() {
    const { title, author, src, mini } = this.props
    return (
      <div style={{textAlign: 'center'}}>
        <Modal
          open={this.state.open}
          onClose={this.closeModal}
          center
          styles={{
            modal: {
              padding: 0,
            },
          }}
          showCloseIcon={true}
        >
          <img src={src} alt={title} />
          <div style={{ padding: '0 15px 20px 15px' }}>
            <span style={{ fontSize: 18, fontWeight: 'bold' }}>{author}</span>
            <br />
            <span style={{ fontSize: 18, color: '#333' }}>{title}</span>
          </div>
        </Modal>

          {
              mini ? <div className={style.lightboxImgCaption}
                          alt={title}
                          style={{
                              width: 250,
                              height: 200,
                              margin: 10,
                              background: `url(${mini})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center center',
                          }}
                          onClick={() => {
                              this.openModal()
                          }}
                  />
                  : <img className={style.lightboxImgCaption}
                        src={src}
                        alt={title}
                        onClick={() => {
                          this.openModal()
                        }}
                      />

          }


          {/*<div className={style.lightboxImgCaption}*/}
          {/*     alt={title}*/}
          {/*    style={{*/}
          {/*        width: 250,*/}
          {/*        height: 200,*/}
          {/*        margin: 10,*/}
          {/*        background: `url(${mini})`,*/}
          {/*        backgroundSize: 'cover',*/}
          {/*        backgroundPosition: 'center center',*/}
          {/*    }}*/}
          {/*     onClick={() => {*/}
          {/*         this.openModal()*/}
          {/*     }}*/}
          {/*/>*/}



      </div>
    )
  }
}

export default LightboxImage
